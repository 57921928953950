//Firebase
import { initializeApp } from 'firebase/app';
import { enableIndexedDbPersistence, initializeFirestore, CACHE_SIZE_UNLIMITED } from 'firebase/firestore';
import { getAuth } from 'firebase/auth'
// import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyDE9hKB6a-rOTrzzmP0igBehKu37hHsdPg",
  authDomain: "treebuhotels.firebaseapp.com",
  databaseURL: "https://treebuhotels.firebaseio.com",
  projectId: "treebuhotels",
  storageBucket: "treebuhotels.appspot.com",
  messagingSenderId: "675670274210",
  appId: "1:675670274210:web:e53e7d6a138938e1ec27fb",
  measurementId: "G-TGJNC219HZ"
};

//initialize Firebase
const app = initializeApp(firebaseConfig);

//Initialize Admin Instance
// const tempApp = initializeApp(firebaseConfig, 'admin')

//DB
export const db = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED
});

enableIndexedDbPersistence(db)
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      //console.log('Multiple tabs open, persistence can only be enabled in one tab at a time')
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === 'unimplemented') {
      //console.log('The current browser does not support all of the features required to enable persistence')
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });

//Auth
export const auth = getAuth(app)
// export const tempAuth = getAuth(tempApp)

// setPersistence(auth, browserSessionPersistence)
//   .then(() => {
//     // Existing and future Auth states are now persisted in the current
//     // session only. Closing the window would clear any existing state even
//     // if a user forgets to sign out.
//     // ...
//     // New sign-in will be persisted with session persistence.
//     // return signInWithEmailAndPassword(auth, email, password);
//   })
//   .catch((error) => {
//     // Handle Errors here.
//     //console.log(error)
//     // const errorCode = error.code;
//     // const errorMessage = error.message;
//     console.log(error)
//   });

//Storage
// export const storage = getStorage(app)
