<template>
  <div>
    <!--    <navbar />-->
    <main>
      <section class="relative w-full h-full py-40 min-h-screen">
        <div
          class="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
          :style="`background-image: url('${registerBg2}');`"
        ></div>
        <router-view />
        <footer-small absolute />
      </section>
    </main>
  </div>
</template>
<script setup>
/* eslint-disable */
import Navbar from "@/components/Navbars/AuthNavbar.vue";
import FooterSmall from "@/components/Footers/FooterSmall.vue";
// eslint-disable-next-line no-unused-vars
import registerBg2 from "@/assets/img/register_bg_2.png";
// eslint-disable-next-line no-unused-vars
// import patternVue from "@/assets/img/pattern_vue.png";
import {defineComponent} from "vue";


defineComponent({
  components: {
    // Navbar,
    FooterSmall
  }
})
// export default {
//   data() {
//     return {
//       registerBg2,
//     };
//   },
//   components: {
//     Navbar,
//     FooterSmall,
//   },
// };
</script>
