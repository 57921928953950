import { createApp } from "vue";

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";

// routes
import {createRouter} from './router';
const router = createRouter()
import { createPinia, PiniaVuePlugin } from 'pinia'
import piniaPersist from 'pinia-plugin-persist'
import { useCommonStore } from "./stores/common";
const pinia = createPinia()
pinia.use(piniaPersist)
createApp(App)
    .use(pinia)
    .use(PiniaVuePlugin)
    .use(router)
    .mount("#app");

const commonStore = useCommonStore();

router.beforeEach((to, from, next) => {
  const requiresAuth = to.meta.requiresAuth;
  const isAuthenticated = commonStore.isAuthenticated;
  switch (true) {
    case requiresAuth && !isAuthenticated:
      next({path: "/auth/login"});
      break
    case !requiresAuth && isAuthenticated:
      next({ name: 'landing' });
      break;
    // case to.name === from.name:
    //   next(false);
    //   break;
    default:
      next();
  }
})
