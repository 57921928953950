/* eslint-disable */
import { defineStore } from 'pinia'
import version from "../../package.json"
import { db } from "../firebase/config"
import { addDoc, collection } from 'firebase/firestore'
import dayjs from "dayjs";

export const useCommonStore = defineStore({
  id: 'common',
  state: () => ({
    appVersion: version.version,
    masterLoader: false,
    isAuthenticated: false,
    currentUser: null,
  }),
  getters: {
    //
  },
  actions: {
    async newTicket(ticketData) {
        ticketData.authUID = this.currentUser
      // console.log(ticketData)
      ticketData.datesArray = [dayjs().millisecond(0).second(0).minute(0).hour(0).toDate()]
      await addDoc(collection(db, "it", "support", "tickets"), ticketData)
          .then(() => {
            console.log('Ticket done')
            this.notifyEmail(ticketData)
            alert('Se ha enviado el ticket, a soporte, responderemos tan pronto como sea posible')
          })
          .catch(e => {
            console.log(e)
          })
    },
    async notifyEmail(ticketData) {
      // console.log(ticketData)
      await addDoc(collection(db, "outbox"), {
          type: "supportTicket",
          itName: 'Josue',
          requester: ticketData.requester,
          task: ticketData.task,
          description: ticketData.description
      })
          .then(() => {
            console.log('Mail sent')
          })
          .catch(e => {
            console.log(e)
          })
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'commonStorage',
        storage: sessionStorage,
        paths: ['isAuthenticated', 'currentUser']
      },
    ],
  },
})
