<template>
  <div id="app">
    <RouterView />
  </div>
</template>
<script setup>
/* eslint-disable */
import { auth } from "./firebase/config";
import { onAuthStateChanged } from 'firebase/auth'
import {onMounted} from "vue";
import {useCommonStore} from "./stores/common";

onMounted(async () => {
  await onAuthStateChanged(auth, (user) => {
    const commonStore = useCommonStore()
    commonStore.isAuthenticated = !!user
    if (user) {
      const uid = user.uid;
      commonStore.currentUser = uid
      console.log(uid)
    } else {
      localStorage.clear();
      sessionStorage.clear();
    }
  })
})
</script>