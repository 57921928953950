<template>
  <div>
<!--    <navbar />-->
    <main>
      <!--      <div class="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">-->
      <!--      <div class="relative pt-32 pb-32 flex content-center items-center justify-center">-->
      <!--        <div-->
      <!--          class="absolute top-0 w-full h-full bg-center bg-cover"-->
      <!--          style="-->
      <!--            background-image: url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80');-->
      <!--          "-->
      <!--        >-->
      <!--          <span-->
      <!--            id="blackOverlay"-->
      <!--            class="w-full h-full absolute opacity-75 bg-black"-->
      <!--          ></span>-->
      <!--        </div>-->
      <!--        <div class="container relative mx-auto">-->
      <!--          <div class="items-center flex flex-wrap">-->
      <!--            <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">-->
      <!--              <div class="pr-12">-->
      <!--                <h1 class="text-white font-semibold text-5xl">-->
      <!--                  Symphony Group - IT-->
      <!--                </h1>-->
      <!--                <p class="mt-4 text-lg text-blueGray-200">-->
      <!--                  Todo problema se aborda mejor con una buena actitud-->
      <!--                </p>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--&lt;!&ndash;        <div&ndash;&gt;-->
      <!--&lt;!&ndash;          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"&ndash;&gt;-->
      <!--&lt;!&ndash;          style="transform: translateZ(0);"&ndash;&gt;-->
      <!--&lt;!&ndash;        >&ndash;&gt;-->
      <!--&lt;!&ndash;          <svg&ndash;&gt;-->
      <!--&lt;!&ndash;            class="absolute bottom-0 overflow-hidden"&ndash;&gt;-->
      <!--&lt;!&ndash;            xmlns="http://www.w3.org/2000/svg"&ndash;&gt;-->
      <!--&lt;!&ndash;            preserveAspectRatio="none"&ndash;&gt;-->
      <!--&lt;!&ndash;            version="1.1"&ndash;&gt;-->
      <!--&lt;!&ndash;            viewBox="0 0 2560 100"&ndash;&gt;-->
      <!--&lt;!&ndash;            x="0"&ndash;&gt;-->
      <!--&lt;!&ndash;            y="0"&ndash;&gt;-->
      <!--&lt;!&ndash;          >&ndash;&gt;-->
      <!--&lt;!&ndash;            <polygon&ndash;&gt;-->
      <!--&lt;!&ndash;              class="text-blueGray-200 fill-current"&ndash;&gt;-->
      <!--&lt;!&ndash;              points="2560 0 2560 100 0 100"&ndash;&gt;-->
      <!--&lt;!&ndash;            ></polygon>&ndash;&gt;-->
      <!--&lt;!&ndash;          </svg>&ndash;&gt;-->
      <!--&lt;!&ndash;        </div>&ndash;&gt;-->
      <!--      </div>-->

      <!--      <section class="pb-20 bg-blueGray-200 -mt-24">-->
      <!--        <div class="container mx-auto px-4">-->
      <!--          <div class="flex flex-wrap">-->
      <!--            <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">-->
      <!--              <div-->
      <!--                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"-->
      <!--              >-->
      <!--                <div class="px-4 py-5 flex-auto">-->
      <!--                  <div-->
      <!--                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400"-->
      <!--                  >-->
      <!--                    <i class="fas fa-award"></i>-->
      <!--                  </div>-->
      <!--                  <h6 class="text-xl font-semibold">Awarded Agency</h6>-->
      <!--                  <p class="mt-2 mb-4 text-blueGray-500">-->
      <!--                    Divide details about your product or agency work into parts.-->
      <!--                    A paragraph describing a feature will be enough.-->
      <!--                  </p>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->

      <!--            <div class="w-full md:w-4/12 px-4 text-center">-->
      <!--              <div-->
      <!--                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"-->
      <!--              >-->
      <!--                <div class="px-4 py-5 flex-auto">-->
      <!--                  <div-->
      <!--                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400"-->
      <!--                  >-->
      <!--                    <i class="fas fa-retweet"></i>-->
      <!--                  </div>-->
      <!--                  <h6 class="text-xl font-semibold">Free Revisions</h6>-->
      <!--                  <p class="mt-2 mb-4 text-blueGray-500">-->
      <!--                    Keep you user engaged by providing meaningful information.-->
      <!--                    Remember that by this time, the user is curious.-->
      <!--                  </p>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->

      <!--            <div class="pt-6 w-full md:w-4/12 px-4 text-center">-->
      <!--              <div-->
      <!--                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"-->
      <!--              >-->
      <!--                <div class="px-4 py-5 flex-auto">-->
      <!--                  <div-->
      <!--                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400"-->
      <!--                  >-->
      <!--                    <i class="fas fa-fingerprint"></i>-->
      <!--                  </div>-->
      <!--                  <h6 class="text-xl font-semibold">Verified Company</h6>-->
      <!--                  <p class="mt-2 mb-4 text-blueGray-500">-->
      <!--                    Write a few lines about each one. A paragraph describing a-->
      <!--                    feature will be enough. Keep you user engaged!-->
      <!--                  </p>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->

      <!--          <div class="flex flex-wrap items-center mt-32">-->
      <!--            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">-->
      <!--              <div-->
      <!--                class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"-->
      <!--              >-->
      <!--                <i class="fas fa-user-friends text-xl"></i>-->
      <!--              </div>-->
      <!--              <h3 class="text-3xl mb-2 font-semibold leading-normal">-->
      <!--                Working with us is a pleasure-->
      <!--              </h3>-->
      <!--              <p-->
      <!--                class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600"-->
      <!--              >-->
      <!--                Don't let your uses guess by attaching tooltips and popoves to-->
      <!--                any element. Just make sure you enable them first via-->
      <!--                JavaScript.-->
      <!--              </p>-->
      <!--              <p-->
      <!--                class="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600"-->
      <!--              >-->
      <!--                The kit comes with three pre-built pages to help you get started-->
      <!--                faster. You can change the text and images and you're good to-->
      <!--                go. Just make sure you enable them first via JavaScript.-->
      <!--              </p>-->
      <!--              <router-link to="/" class="font-bold text-blueGray-700 mt-8">-->
      <!--                Check Vue Notus!-->
      <!--              </router-link>-->
      <!--            </div>-->

      <!--            <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">-->
      <!--              <div-->
      <!--                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-emerald-500"-->
      <!--              >-->
      <!--                <img-->
      <!--                  alt="..."-->
      <!--                  src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1051&q=80"-->
      <!--                  class="w-full align-middle rounded-t-lg"-->
      <!--                />-->
      <!--                <blockquote class="relative p-8 mb-4">-->
      <!--                  <svg-->
      <!--                    preserveAspectRatio="none"-->
      <!--                    xmlns="http://www.w3.org/2000/svg"-->
      <!--                    viewBox="0 0 583 95"-->
      <!--                    class="absolute left-0 w-full block h-95-px -top-94-px"-->
      <!--                  >-->
      <!--                    <polygon-->
      <!--                      points="-30,95 583,95 583,65"-->
      <!--                      class="text-emerald-500 fill-current"-->
      <!--                    ></polygon>-->
      <!--                  </svg>-->
      <!--                  <h4 class="text-xl font-bold text-white">-->
      <!--                    Top Notch Services-->
      <!--                  </h4>-->
      <!--                  <p class="text-md font-light mt-2 text-white">-->
      <!--                    The Arctic Ocean freezes every winter and much of the-->
      <!--                    sea-ice then thaws every summer, and that process will-->
      <!--                    continue whatever happens.-->
      <!--                  </p>-->
      <!--                </blockquote>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </section>-->

      <!--      <section class="relative py-20">-->
      <!--        <div-->
      <!--          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"-->
      <!--          style="transform: translateZ(0);"-->
      <!--        >-->
      <!--          <svg-->
      <!--            class="absolute bottom-0 overflow-hidden"-->
      <!--            xmlns="http://www.w3.org/2000/svg"-->
      <!--            preserveAspectRatio="none"-->
      <!--            version="1.1"-->
      <!--            viewBox="0 0 2560 100"-->
      <!--            x="0"-->
      <!--            y="0"-->
      <!--          >-->
      <!--            <polygon-->
      <!--              class="text-white fill-current"-->
      <!--              points="2560 0 2560 100 0 100"-->
      <!--            ></polygon>-->
      <!--          </svg>-->
      <!--        </div>-->

      <!--        <div class="container mx-auto px-4">-->
      <!--          <div class="items-center flex flex-wrap">-->
      <!--            <div class="w-full md:w-4/12 ml-auto mr-auto px-4">-->
      <!--              <img-->
      <!--                alt="..."-->
      <!--                class="max-w-full rounded-lg shadow-lg"-->
      <!--                src="https://images.unsplash.com/photo-1555212697-194d092e3b8f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"-->
      <!--              />-->
      <!--            </div>-->
      <!--            <div class="w-full md:w-5/12 ml-auto mr-auto px-4">-->
      <!--              <div class="md:pr-12">-->
      <!--                <div-->
      <!--                  class="text-emerald-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-emerald-300"-->
      <!--                >-->
      <!--                  <i class="fas fa-rocket text-xl"></i>-->
      <!--                </div>-->
      <!--                <h3 class="text-3xl font-semibold">A growing company</h3>-->
      <!--                <p class="mt-4 text-lg leading-relaxed text-blueGray-500">-->
      <!--                  The extension comes with three pre-built pages to help you get-->
      <!--                  started faster. You can change the text and images and you're-->
      <!--                  good to go.-->
      <!--                </p>-->
      <!--                <ul class="list-none mt-6">-->
      <!--                  <li class="py-2">-->
      <!--                    <div class="flex items-center">-->
      <!--                      <div>-->
      <!--                        <span-->
      <!--                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-emerald-600 bg-emerald-200 mr-3"-->
      <!--                        >-->
      <!--                          <i class="fas fa-fingerprint"></i>-->
      <!--                        </span>-->
      <!--                      </div>-->
      <!--                      <div>-->
      <!--                        <h4 class="text-blueGray-500">-->
      <!--                          Carefully crafted components-->
      <!--                        </h4>-->
      <!--                      </div>-->
      <!--                    </div>-->
      <!--                  </li>-->
      <!--                  <li class="py-2">-->
      <!--                    <div class="flex items-center">-->
      <!--                      <div>-->
      <!--                        <span-->
      <!--                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-emerald-600 bg-emerald-200 mr-3"-->
      <!--                        >-->
      <!--                          <i class="fab fa-html5"></i>-->
      <!--                        </span>-->
      <!--                      </div>-->
      <!--                      <div>-->
      <!--                        <h4 class="text-blueGray-500">-->
      <!--                          Amazing page examples-->
      <!--                        </h4>-->
      <!--                      </div>-->
      <!--                    </div>-->
      <!--                  </li>-->
      <!--                  <li class="py-2">-->
      <!--                    <div class="flex items-center">-->
      <!--                      <div>-->
      <!--                        <span-->
      <!--                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-emerald-600 bg-emerald-200 mr-3"-->
      <!--                        >-->
      <!--                          <i class="far fa-paper-plane"></i>-->
      <!--                        </span>-->
      <!--                      </div>-->
      <!--                      <div>-->
      <!--                        <h4 class="text-blueGray-500">Dynamic components</h4>-->
      <!--                      </div>-->
      <!--                    </div>-->
      <!--                  </li>-->
      <!--                </ul>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </section>-->

      <!--      <section class="pt-20 pb-48">-->
      <!--        <div class="container mx-auto px-4">-->
      <!--          <div class="flex flex-wrap justify-center text-center mb-24">-->
      <!--            <div class="w-full lg:w-6/12 px-4">-->
      <!--              <h2 class="text-4xl font-semibold">Here are our heroes</h2>-->
      <!--              <p class="text-lg leading-relaxed m-4 text-blueGray-500">-->
      <!--                According to the National Oceanic and Atmospheric-->
      <!--                Administration, Ted, Scambos, NSIDClead scentist, puts the-->
      <!--                potentially record maximum.-->
      <!--              </p>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="flex flex-wrap">-->
      <!--            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">-->
      <!--              <div class="px-6">-->
      <!--                <img-->
      <!--                  alt="..."-->
      <!--                  :src="team1"-->
      <!--                  class="shadow-lg rounded-full mx-auto max-w-120-px"-->
      <!--                />-->
      <!--                <div class="pt-6 text-center">-->
      <!--                  <h5 class="text-xl font-bold">Ryan Tompson</h5>-->
      <!--                  <p-->
      <!--                    class="mt-1 text-sm text-blueGray-400 uppercase font-semibold"-->
      <!--                  >-->
      <!--                    Web Developer-->
      <!--                  </p>-->
      <!--                  <div class="mt-6">-->
      <!--                    <button-->
      <!--                      class="bg-lightBlue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"-->
      <!--                      type="button"-->
      <!--                    >-->
      <!--                      <i class="fab fa-twitter"></i>-->
      <!--                    </button>-->
      <!--                    <button-->
      <!--                      class="bg-lightBlue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"-->
      <!--                      type="button"-->
      <!--                    >-->
      <!--                      <i class="fab fa-facebook-f"></i>-->
      <!--                    </button>-->
      <!--                    <button-->
      <!--                      class="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"-->
      <!--                      type="button"-->
      <!--                    >-->
      <!--                      <i class="fab fa-dribbble"></i>-->
      <!--                    </button>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">-->
      <!--              <div class="px-6">-->
      <!--                <img-->
      <!--                  alt="..."-->
      <!--                  :src="team2"-->
      <!--                  class="shadow-lg rounded-full mx-auto max-w-120-px"-->
      <!--                />-->
      <!--                <div class="pt-6 text-center">-->
      <!--                  <h5 class="text-xl font-bold">Romina Hadid</h5>-->
      <!--                  <p-->
      <!--                    class="mt-1 text-sm text-blueGray-400 uppercase font-semibold"-->
      <!--                  >-->
      <!--                    Marketing Specialist-->
      <!--                  </p>-->
      <!--                  <div class="mt-6">-->
      <!--                    <button-->
      <!--                      class="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"-->
      <!--                      type="button"-->
      <!--                    >-->
      <!--                      <i class="fab fa-google"></i>-->
      <!--                    </button>-->
      <!--                    <button-->
      <!--                      class="bg-lightBlue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"-->
      <!--                      type="button"-->
      <!--                    >-->
      <!--                      <i class="fab fa-facebook-f"></i>-->
      <!--                    </button>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">-->
      <!--              <div class="px-6">-->
      <!--                <img-->
      <!--                  alt="..."-->
      <!--                  :src="team3"-->
      <!--                  class="shadow-lg rounded-full mx-auto max-w-120-px"-->
      <!--                />-->
      <!--                <div class="pt-6 text-center">-->
      <!--                  <h5 class="text-xl font-bold">Alexa Smith</h5>-->
      <!--                  <p-->
      <!--                    class="mt-1 text-sm text-blueGray-400 uppercase font-semibold"-->
      <!--                  >-->
      <!--                    UI/UX Designer-->
      <!--                  </p>-->
      <!--                  <div class="mt-6">-->
      <!--                    <button-->
      <!--                      class="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"-->
      <!--                      type="button"-->
      <!--                    >-->
      <!--                      <i class="fab fa-google"></i>-->
      <!--                    </button>-->
      <!--                    <button-->
      <!--                      class="bg-lightBlue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"-->
      <!--                      type="button"-->
      <!--                    >-->
      <!--                      <i class="fab fa-twitter"></i>-->
      <!--                    </button>-->
      <!--                    <button-->
      <!--                      class="bg-blueGray-700 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"-->
      <!--                      type="button"-->
      <!--                    >-->
      <!--                      <i class="fab fa-instagram"></i>-->
      <!--                    </button>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">-->
      <!--              <div class="px-6">-->
      <!--                <img-->
      <!--                  alt="..."-->
      <!--                  :src="team4"-->
      <!--                  class="shadow-lg rounded-full mx-auto max-w-120-px"-->
      <!--                />-->
      <!--                <div class="pt-6 text-center">-->
      <!--                  <h5 class="text-xl font-bold">Jenna Kardi</h5>-->
      <!--                  <p-->
      <!--                    class="mt-1 text-sm text-blueGray-400 uppercase font-semibold"-->
      <!--                  >-->
      <!--                    Founder and CEO-->
      <!--                  </p>-->
      <!--                  <div class="mt-6">-->
      <!--                    <button-->
      <!--                      class="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"-->
      <!--                      type="button"-->
      <!--                    >-->
      <!--                      <i class="fab fa-dribbble"></i>-->
      <!--                    </button>-->
      <!--                    <button-->
      <!--                      class="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"-->
      <!--                      type="button"-->
      <!--                    >-->
      <!--                      <i class="fab fa-google"></i>-->
      <!--                    </button>-->
      <!--                    <button-->
      <!--                      class="bg-lightBlue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"-->
      <!--                      type="button"-->
      <!--                    >-->
      <!--                      <i class="fab fa-twitter"></i>-->
      <!--                    </button>-->
      <!--                    <button-->
      <!--                      class="bg-blueGray-700 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"-->
      <!--                      type="button"-->
      <!--                    >-->
      <!--                      <i class="fab fa-instagram"></i>-->
      <!--                    </button>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </section>-->

      <section class="pb-20 relative block bg-blueGray-800">
        <!--        <div-->
        <!--          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"-->
        <!--          style="transform: translateZ(0);"-->
        <!--        >-->
        <!--          <svg-->
        <!--            class="absolute bottom-0 overflow-hidden"-->
        <!--            xmlns="http://www.w3.org/2000/svg"-->
        <!--            preserveAspectRatio="none"-->
        <!--            version="1.1"-->
        <!--            viewBox="0 0 2560 100"-->
        <!--            x="0"-->
        <!--            y="0"-->
        <!--          >-->
        <!--            <polygon-->
        <!--              class="text-blueGray-800 fill-current"-->
        <!--              points="2560 0 2560 100 0 100"-->
        <!--            ></polygon>-->
        <!--          </svg>-->
        <!--        </div>-->

        <div class="container mx-auto px-4 lg:pt-24 lg:pb-64">
          <div class="flex flex-wrap text-center justify-center">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold text-white">
                Solicitud de soporte
              </h2>
              <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
                En pro de maximizar y priorizar las respuestas a las solicitudes de soporte,
                únicamente serán atendidas aquellas realizadas por este medio
              </p>
            </div>
          </div>
          <!--          <div class="flex flex-wrap mt-12 justify-center">-->
          <!--            <div class="w-full lg:w-3/12 px-4 text-center">-->
          <!--              <div-->
          <!--                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"-->
          <!--              >-->
          <!--                <i class="fas fa-medal text-xl"></i>-->
          <!--              </div>-->
          <!--              <h6 class="text-xl mt-5 font-semibold text-white">-->
          <!--                Excelent Services-->
          <!--              </h6>-->
          <!--              <p class="mt-2 mb-4 text-blueGray-400">-->
          <!--                Some quick example text to build on the card title and make up-->
          <!--                the bulk of the card's content.-->
          <!--              </p>-->
          <!--            </div>-->
          <!--            <div class="w-full lg:w-3/12 px-4 text-center">-->
          <!--              <div-->
          <!--                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"-->
          <!--              >-->
          <!--                <i class="fas fa-poll text-xl"></i>-->
          <!--              </div>-->
          <!--              <h5 class="text-xl mt-5 font-semibold text-white">-->
          <!--                Grow your market-->
          <!--              </h5>-->
          <!--              <p class="mt-2 mb-4 text-blueGray-400">-->
          <!--                Some quick example text to build on the card title and make up-->
          <!--                the bulk of the card's content.-->
          <!--              </p>-->
          <!--            </div>-->
          <!--            <div class="w-full lg:w-3/12 px-4 text-center">-->
          <!--              <div-->
          <!--                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"-->
          <!--              >-->
          <!--                <i class="fas fa-lightbulb text-xl"></i>-->
          <!--              </div>-->
          <!--              <h5 class="text-xl mt-5 font-semibold text-white">-->
          <!--                Launch time-->
          <!--              </h5>-->
          <!--              <p class="mt-2 mb-4 text-blueGray-400">-->
          <!--                Some quick example text to build on the card title and make up-->
          <!--                the bulk of the card's content.-->
          <!--              </p>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </section>
      <section class="relative block py-24 lg:pt-0 bg-blueGray-800">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
            <div class="w-full lg:w-6/12 px-4">
              <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200"
              >
                <div class="flex-auto p-5 lg:p-10">
                  <h4 class="text-2xl font-semibold text-center">
                    Completa el formulario
                  </h4>
                  <form @submit.prevent="submit">
                    <div class="relative w-full mb-3 mt-8">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        Nombre completo *
                      </label>
                      <input
                        v-model.trim="ticketData.requester"
                        required
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Nombre Completo"
                      >
                    </div>
                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="subject"
                      >
                        Empresa / Origen *
                      </label>
                      <select
                        v-model="ticketData.origin"
                        required
                        class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                      >
                        <option
                          selected
                          disabled
                        >
                          Selecciona una opción
                        </option>
                        <option value="hpp">
                          Hotel & Spa Poco a Poco
                        </option>
                        <option value="mcl">
                          Monteverde Country Lodge
                        </option>
                        <option value="mrl">
                          Monteverde Rustic Lodge
                        </option>
                        <option value="cmla">
                          Centro Médico Los Ángeles
                        </option>
                        <option value="cla">
                          Clínica Médica Los Ángeles
                        </option>
                        <option value="sgr">
                          Stone Garden Records
                        </option>
                        <option value="symphony">
                          Symphony (Administrativo)
                        </option>
                        <option value="none">
                          No Aplica
                        </option>
                      </select>
                    </div>

                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="subject"
                      >
                        Nivel de urgencia *
                      </label>
                      <select
                        v-model="ticketData.priority"
                        required
                        class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                      >
                        <option
                          selected
                          disabled
                        >
                          Selecciona una opción
                        </option>
                        <option value="veryLow">
                          Puede esperar
                        </option>
                        <option value="low">
                          Baja
                        </option>
                        <option value="medium">
                          Media
                        </option>
                        <option value="high">
                          Alta
                        </option>
                        <option value="veryHigh">
                          Muy Alta
                        </option>
                        <option value="reallyHigh">
                          El fin del mundo como lo conocemos
                        </option>
                      </select>
                    </div>
                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="subject"
                      >
                        Departamento *
                      </label>
                      <select
                        v-model="ticketData.department"
                        required
                        class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                      >
                        <option
                          selected
                          disabled
                        >
                          Selecciona una opción
                        </option>
                        <option value="frontDesk">
                          Recepción
                        </option>
                        <option value="reservations">
                          Reservas
                        </option>
                        <option value="restaurant">
                          Restaurante
                        </option>
                        <option value="accounting">
                          Contabilidad
                        </option>
                        <option value="supply">
                          Proveeduría
                        </option>
                        <option value="wos">
                          WOS
                        </option>
                        <option value="rrhh">
                          RRHH
                        </option>
                        <option value="operations">
                          Operaciones
                        </option>
                        <option value="manager">
                          Gerencia / Administrativos
                        </option>
                        <option value="other">
                          Otro
                        </option>
                      </select>
                    </div>
                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="subject"
                      >
                        AnyDesk
                      </label>
                      <input
                        v-model.trim="ticketData.remoteConnection"
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="De ser posible y si es relevante escribe el código de AnyDesk"
                      >
                    </div>
                    <div class="relative w-full mb-3 mt-8">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="subject"
                      >
                        Asunto *
                      </label>
                      <input
                        v-model.trim="ticketData.task"
                        required
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Titulo del problema"
                      >
                    </div>
                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="message"
                      >
                        Descripción del problema *
                      </label>
                      <textarea
                        v-model.trim="ticketData.description"
                        required
                        rows="4"
                        cols="80"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Sé lo más especifico posible"
                      />
                    </div>
                    <div class="text-center mt-6">
                      <button
                        class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="submit"
                      >
                        Enviar incidencia
                      </button>
                    </div>
                  </form>
                  <hr class="mt-6">
                  <h4 class="text-2xl font-semibold text-center">
                    Tienes una urgencia de alta gravedad?
                  </h4>
                  <p class="leading-relaxed mt-1 mb-4 text-blueGray-500 text-center">
                    Comunicate al teléfono o whatsapp del encargado de TI y recuerda luego llenar el formulario para
                    mantener un registro siempre.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script setup>
/* eslint-disable */
import Navbar from "@/components/Navbars/AuthNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import {defineComponent, ref} from "vue";
import {useCommonStore} from "../stores/common";
const commonStore = useCommonStore()
import dayjs from "dayjs"
import {auth} from "../firebase/config";
// import Footer from "../components/Footers/Footer";

// import team1 from "@/assets/img/team-1-800x800.jpg";
// import team2 from "@/assets/img/team-2-800x800.jpg";
// import team3 from "@/assets/img/team-3-800x800.jpg";
// import team4 from "@/assets/img/team-4-470x470.png";

const ticketData = ref({
  task: '',
  description: '',
  origin: '',
  requester: '',
  department: '',
  remoteConnection: '',
  currentStatus: 'requested',
  category: '',
  timeTrack: {
    requestedAt: dayjs().toDate(),
    acceptedAt: null,
    inProgressAt: null,
    completedAt: null,
  },
  resolution: '',
  priority: 'low',
  authUID:  commonStore.currentUser,
})
const clearFields = () => {
  ticketData.value = {
    task: '',
    description: '',
    origin: '',
    requester: '',
    department: '',
    remoteConnection: '',
    currentStatus: 'requested',
    category: '',
    timeTrack: {
      requestedAt: dayjs().toDate(),
      acceptedAt: null,
      inProgressAt: null,
      completedAt: null,
    },
    resolution: '',
    priority: 'low',
    authUID:  commonStore.currentUser,
  }
}
const submit = async () => {
  await commonStore.newTicket(ticketData.value).then(() => {
    clearFields()
  })

}
defineComponent({
  components: {
    Navbar,
    FooterComponent
  }
})
// export default {
//   components: {
//     Navbar,
//     FooterComponent,
//   },
//   data() {
//     return {
//       team1,
//       team2,
//       team3,
//       team4,
//     };
//   },
// };
</script>
