import {createRouter as createClientRouter, createWebHistory,} from 'vue-router'

import routes from "./routes";

export function createRouter() {
    return createClientRouter({
        history: createWebHistory(),
        routes,
    })
}
