import Landing from "@/views/Landing.vue";
// layouts

// import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";

// views for Admin layout

// import Dashboard from "@/views/admin/Dashboard.vue";
// import Settings from "@/views/admin/Settings.vue";
// import Tables from "@/views/admin/Tables.vue";
// import Maps from "@/views/admin/Maps.vue";

// views for Auth layout

import Login from "@/views/auth/Login.vue";
// import Register from "@/views/auth/Register.vue";

// views without layouts


// import Profile from "@/views/Profile.vue";
// import Index from "@/views/Index.vue";

const routes = [
    // {
    //   path: "/admin",
    //   redirect: "/admin/dashboard",
    //   component: Admin,
    //   children: [
    //     {
    //       path: "/admin/dashboard",
    //       component: Dashboard,
    //     },
    //     {
    //       path: "/admin/settings",
    //       component: Settings,
    //     },
    //     {
    //       path: "/admin/tables",
    //       component: Tables,
    //     },
    //     {
    //       path: "/admin/maps",
    //       component: Maps,
    //     },
    //   ],
    // },
    {
        path: "/",
        redirect: "/auth/login",
        component: Auth,
        props: true,
        meta: { requiresAuth: false },
        children: [
            {
                path: "/auth/login",
                component: Login,
            },
            // {
            //   path: "/auth/register",
            //   component: Register,
            // },
        ],
    },
    {
        path: "/landing",
        component: Landing,
        name: 'landing',
        props: true,
        meta: { requiresAuth: true },
    },
    // {
    //   path: "/profile",
    //   component: Profile,
    // },
    // {
    //   path: "/",
    //   component: Index,
    // },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/",
        props: true,
        meta: { requiresAuth: false }
    },
];

export default routes