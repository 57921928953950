<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <div class="text-blueGray-400 text-center font-bold my-6">
              <small>Inicia sesión para solicitar soporte</small>
            </div>
            <form>
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Email
                </label>
                <input
                  v-model="userName"
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Email"
                >
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Contraseña
                </label>
                <input
                  v-model="password"
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Password"
                >
              </div>
              <!--              <div>-->
              <!--                <label class="inline-flex items-center cursor-pointer">-->
              <!--                  <input-->
              <!--                    id="customCheckLogin"-->
              <!--                    type="checkbox"-->
              <!--                    class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"-->
              <!--                  />-->
              <!--                  <span class="ml-2 text-sm font-semibold text-blueGray-600">-->
              <!--                    Remember me-->
              <!--                  </span>-->
              <!--                </label>-->
              <!--              </div>-->

              <div class="text-center mt-6">
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="button"
                  @click="login"
                >
                  Acceder
                </button>
              </div>
            </form>
          </div>
        </div>
        <!--        <div class="flex flex-wrap mt-6 relative">-->
        <!--          <div class="w-1/2">-->
        <!--            <a href="javascript:void(0)" class="text-blueGray-200">-->
        <!--              <small>Forgot password?</small>-->
        <!--            </a>-->
        <!--          </div>-->
        <!--          <div class="w-1/2 text-right">-->
        <!--            <router-link to="/auth/register" class="text-blueGray-200">-->
        <!--              <small>Create new account</small>-->
        <!--            </router-link>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>
<script setup>
/* eslint-disable */
// import github from "@/assets/img/github.svg";
// import google from "@/assets/img/google.svg";
import {ref} from "vue";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase/config";
import {useRouter} from "vue-router";
import {useCommonStore} from "../../stores/common";
const commonStore = useCommonStore()
const router = useRouter()
const userName = ref('')
const password = ref('')
const getEmail = (email) => {
  let checkEmail = email.split('@')[1]
  if (checkEmail === undefined || checkEmail === '') {
    return email.trim() + '@symphonycr.com'
  } else {
    return email.trim()
  }
}
const login = async () => {
  await signInWithEmailAndPassword(auth, getEmail(userName.value), password.value)
      .then(() => {
        router.replace({name: 'landing'})
      })
      .catch((e) => {
        console.log(e)
        switch (e.code) {
          case 'auth/wrong-password':
            // Swal.fire({
            //   title: 'Contraseña Incorrecta',
            //   text: 'La contraseña que has ingresado no es correcta',
            //   icon: 'error',
            //   confirmButtonText: 'Entiendo, volveré a intentar',
            //   showClass: {
            //     popup: 'animate backInDown',
            //   },
            //   hideClass: {
            //     popup: 'animate backOutDown',
            //   },
            // }).then((result) => {
            //   if (result.value) {
            //     signOut()
            //   }
            // })
            break
          case 'auth/user-not-found':
            // Swal.fire({
            //   title: 'Hola extraño!',
            //   text: 'No reconocemos tu usuario, revisa que esté bien escrito',
            //   icon: 'error',
            //   confirmButtonText: 'Entiendo, volveré a intentar',
            //   showClass: {
            //     popup: 'animate backInDown',
            //   },
            //   hideClass: {
            //     popup: 'animate backOutDown',
            //   },
            // })
            break
          case 'auth/user-disabled':
            // Swal.fire({
            //   title: 'Oups!',
            //   text: 'Parece que tu usuario ha sido desactivado, consulta con un administrador',
            //   icon: 'error',
            //   confirmButtonText: 'Entiendo',
            //   showClass: {
            //     popup: 'animate backInDown',
            //   },
            //   hideClass: {
            //     popup: 'animate backOutDown',
            //   },
            // })
            break
          default:
            // Swal.fire({
            //   title: 'Oups',
            //   text: 'El siguiente error se presentó:' + ' => ' + e,
            //   icon: 'error',
            //   confirmButtonText: 'Comprendo',
            //   showClass: {
            //     popup: 'animate fadeInDown',
            //   },
            //   hideClass: {
            //     popup: 'animate fadeOutUp',
            //   },
            // })
        }
      })
}

</script>
